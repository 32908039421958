import HTTP from "@/services/axios";
import { sendSuggestion, getUsers, editUserEmail, editUserPassword, editUserBlockedStatus, getUserSessions, getUserManagements } from "@/services/paths";

export default {
  state: {
    users: [],
    userManagements: [],
  },
  mutations:{
    setUsers(state, users){
      state.users = users
    },
    setUserManagements(state, userManagements){
      state.userManagements = userManagements
    }
  },
  actions: {
    async sendingSuggest(_, data) {
      const res = await HTTP.post(sendSuggestion(), data);
      console.log(res);
    },
    async fetchUsers({commit}){
      const res = await HTTP.get(getUsers())
      commit('setUsers', res.data.data)
    },
    async toogleUserBlockedStatus(_, data){
      await HTTP.put(editUserBlockedStatus(), data)
    },
    async updateUserEmail(_, data){
      await HTTP.put(editUserEmail(), data)
    },
    async updateUserPassword(_, data){
      await HTTP.put(editUserPassword(), data)
    },
    async fetchSessions(_, id){
      const res = await HTTP.get(getUserSessions(id))
      return res.data.data
    },
    async fetchUserManagements({commit}, id) {
      const res = await HTTP.get(getUserManagements(id));
      console.log(res)
      commit('setUserManagements', res.data)
    }
  },
};
