import store from '@/store/index'; // Importa los objeto de la store de Vuex.

export function resetStore() {
    const stateKeys = Object.keys(store.state); // Obtiene los nombres de las variables de la store en Vuex.

    const getStoreName = {} // Variable que almacenará los nombres de las variables como propiedades vacias.

    stateKeys && stateKeys.map((index) => {
        getStoreName[index] = {} // Asigna los nombres de las propiedades.
    })

    return getStoreName
}

export async function dispatchStore(dispatchName, body = {}) {
    const { dispatch } = store
    try {
      await dispatch(dispatchName, body)
      return { success: true }
    } catch (error) {
      console.log(error)
      const errorMessage = error?.response?.data?.message || (error.message === "Network Error" ? "Error de conexión" : error.response.data)
      return { success: false, error: errorMessage }
    }
  }