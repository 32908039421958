const treasuryRoutes = [
    {
        path: "historial-pagos-impuestos",
        name: "Historial-pagos-impuestos",
        component: () => import("@/views/treasury/ViewPaymentHistoryTax.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Historial de pagos",
            title: "Historial de pagos",
        }
    }, {
        path: "consulta-cuentas",
        name: "Consulta-cuentas",
        component: () => import("@/views/treasury/ViewAccount.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Cuentas",
            title: "Cuentas",
        }
    }, {
        path: "historial-diferencial-ingresos",
        name: "Historial-diferencial-ingresos",
        component: () => import("@/views/treasury/ViewIncomeDifferences.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Historial de diferencial de ingresos",
            title: "Historial de diferencial de ingresos",
        }
    }, {
        path: "estado-cuenta-npe",
        name: "Estado-cuenta-npe",
        component: () => import("@/views/treasury/ViewAccountStatus.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Estado de cuenta npe",
            title: "Estado de cuenta npe",
        }
    }, {
        path: "historial-recibos-contribuyente",
        name: "Historial-recibos-contribuyente",
        component: () => import("@/views/treasury/searchTaxpayerReceipt.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Historial de recibos de contribuyentes",
            title: "Historial de recibos de contribuyentes",
        }
    },
    {
        path:"ingresos-std",
        name:"Ingresos-std",
        component:()=> import("@/views/treasury/ViewIncomeMoa.vue"),
        meta:{
            requiresAuth: true,
            breadcrumb:"Detalle de ingresos Santa Tecla Digital",
            title:"Detalle de ingresos Santa Tecla Digital"
        }
    },{
        path:"generar-enlace-pagos",
        name:"Generar-enlace-pagos",
        component:()=> import("@/views/treasury/ViewGeneratePaymentsLinks.vue"),
        meta:{
            requiresAuth:true,
            breadcrumb:"Generar Enlaces de Pago",
            title:"Generar Enlaces de Pago"
        }
    }
]

export {
    treasuryRoutes
}