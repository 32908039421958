import { createStore /*, createLogger*/ } from "vuex"
import root from "./modules/root"
import auth from "./modules/auth"
import account from "./modules/account"
import check from "./modules/check"
import solvency from "./modules/solvency"
import tax from "./modules/tax"
import portfolio from "./modules/portfolio"
import certifications from "./modules/certifications"
import constancy from "./modules/constancy"
import employee from "./modules/employee"
import tributary from "./modules/tributary"
import publicData from "./modules/public"
import createPersistedState from "vuex-persistedstate"
import user from "./modules/user"
import licence from "./modules/licence"
import common from "./modules/common"

export default createStore({
    state: {
    },
    getters: {
    },
    mutations: {
    },
    actions: {
    },
    modules: {
        root,
        check,
        auth,
        account,
        solvency,
        portfolio,
        tax,
        certifications,
        constancy,
        employee,
        tributary,
        publicData,
        user,
        licence,
        common
    },
    plugins: [/*createLogger(),*/ createPersistedState({paths: ['auth']})]
})