//Others
import { createRouter, createWebHistory } from "vue-router"
//Component
import { rootRoutes } from "@/router/root/rootRoutes"
import { authRoutes } from "@/router/auth/authRoutes"
import { userRoutes } from "@/router/user/userRoutes"
import { tributaryRoutes } from "@/router/tributary/tributaryRoutes"
import { adminRoutes } from "@/router/admin/adminRoutes"
import { publicRoutes } from "@/router/public/publicRoutes"
import { verifyRoutes } from "@/router/verify/verifyRoutes"
import { treasuryRoutes } from "@/router/treasury/treasuryRoutes"
import { portfolioRoutes } from "@/router/portfolio/portfolioRoutes"
import { refRoutes } from "@/router/ref/refRoutes"
import { constancyRoutes } from "@/router/constancy/constancyRoutes"
import { employeeRoutes } from "@/router/employee/employeeRoutes"
import { formRoutes } from "./form/formRoutes"
import { catastroRoutes } from "./catastro/catastroRoutes"
import { supportRoutes } from "./support/supportRoutes"
import { adsRoutes } from "./ads/adsRoutes"


//Routes
const routes = [
    ...authRoutes,
    ...publicRoutes,
    {
        path: "/:role",
        component: () => import("@/views/ViewSystem.vue"),
        children: [
            ...rootRoutes,
            ...userRoutes,
            ...adminRoutes,
            ...verifyRoutes,
            ...treasuryRoutes,
            ...portfolioRoutes,
            ...refRoutes,
            ...constancyRoutes,
            ...employeeRoutes,
            ...tributaryRoutes,
            ...formRoutes,
            ...catastroRoutes,
            ...supportRoutes,
            ...adsRoutes
        ]
    },
    {
        path: "/cuenta",
        name: "Cuenta",
        component: () => import("@/views/user/ViewAccount.vue"),
        meta: {
            requiresAuth: true,
            breadcrumb: "Cuenta",
            title: "Cuenta",
        }
    },
    {
        path: "/opciones-usuario",
        name: "Opciones-usuario",
        component: () => import("@/views/auth/ViewSystemOption.vue"),
        meta: {
            requiresAuth: true,
            title: "Opciones de usuario",
        }
    },
    {
        path:"/solicitud-verificacion",
        name:"Solicitud-verificacion",
        component:()=>import("@/views/user/ViewVerifyAccount.vue"),
        meta:{
            requiresAuth: true,
            title: "Solicitud de verificación",
        }
    },
    {
        path: "/:pathMatch(.*)*",
        name: "Notfound",
        component: () => import("@/views/ViewNotFound.vue")
    }
]

//Initialization
const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior() {
        document.getElementById("app").scrollIntoView({ behavior: "smooth" });
    }
})

//Guards
router.beforeEach(async (to, from, next) => {

    const token = localStorage.getItem('token')
    
    // Obtener el estado de autenticación de  Vuex
    const dateLogin = localStorage.getItem('dateLogin') && JSON.parse(localStorage.getItem('dateLogin'))

    // Verificar si la fecha de inicio de sesión es anterior a la fecha y hora actual
    // y si existe un token de autenticación
    if (new Date(dateLogin) < new Date() && token) {
        localStorage.removeItem('token')
        localStorage.removeItem('dateLogin')
    }

    // onAuthStateChanged (falta contexto para añadir comentarios)

    // Verificar si la ruta requiere autenticación basándose en la propiedad meta "requiresAuth"
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

    // Verificar si se requiere autenticación y el usuario no ha iniciado sesión
    if (requiresAuth && !token) {

        // Redirigir al usuario a la página principal ("/")
        next("/");
    } else {

        // Si la ruta tiene un título, establecerlo como título de la página
        const title = to.meta.title

        if (title) {
            document.title = title
        }

        // Si la ruta es / y esta autenticado redirigir a /opciones-usuario
        if (to.path === "/" && token) {
            
            next("/opciones-usuario");
        } else {
            // Continuar con la navegación sin interrupciones
            next();
        }

    }
});


export default router
