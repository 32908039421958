//Others
import App from "./App.vue"
import store from "./store"
import router from "./router"
import { createApp } from "vue"
import Antd from "ant-design-vue"
import VueTheMask from "vue-the-mask"
import CKEditor from '@ckeditor/ckeditor5-vue';
import { handleError } from "./utils/error-handler"

//Css
import "../public/css/antd.css"
import "../public/css/main.css"
import "../public/css/card.css"

const app = createApp(App)

app.config.errorHandler = (err, instance, info) => handleError(err, instance, info)

//Statement
app.use(store)
    .use(Antd)
    .use(router)
    .use(VueTheMask)
    .use(CKEditor)
    .mount("#app")

    