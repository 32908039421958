import axios from 'axios'
import { Modal, message } from 'ant-design-vue'
import router from '@/router'; 

const timeout = process.env.VUE_APP_TIMEOUT

const HTTP = axios.create({
    baseURL: process.env.VUE_APP_URL_API + `/api/`,
    headers: {
        'Cache-Control': 'no-cache',
    }
})

HTTP.defaults.timeout = timeout ? timeout : 30000

HTTP.interceptors.request.use(
    config => {
        let myToken = localStorage.getItem('token')
        if (myToken != null) {
            config.headers.Authorization = myToken
        }
        return config
    }
)

HTTP.interceptors.response.use(
    response => {
        return response
    },
    error => {
        console.log(error);
        if (!error.response) {
            if (error.code === 'ECONNABORTED') {
                message.error('El servidor no responde, intente nuevamente.')
            } 

            return Promise.reject(error)
        }

        if (
            error.response.status === 401 && 
            (
                error.response.data.code === 'USER_NOT_ACTIVE' || 
                error.response.data.code === 'USER_NOT_FOUND' ||
                error.response.data.code === 'TOKEN_NOT_PROVIDED' ||
                error.response.data.code === 'TOKEN_INVALID'
            )
        ) {
            localStorage.removeItem('token')
            message.error(error.response.data.message)
            router.replace('/');
        } else if (
            error.response.status === 401 && 
            error.response.data.code === 'USER_NOT_VERIFIED'
        ) {
            console.log('hola');
            Modal.confirm({
                title: 'Usuario no verificado',
                content: 'Verifique su usuario para usar este servicio',
                okText: 'Verificarme',
                onOk() {
                    router.replace('/solicitud-verificacion');
                },
                onCancel(){
                    router.replace("/")
                }
            })
        }else { 
            return Promise.reject(error)
        }
    }
)


export default HTTP