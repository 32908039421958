<template>
  <a-config-provider :locale="locale">
    <title>Alcaldía de La Libertad Sur, Distrito Santa Tecla</title>
    <FloatingWhatsAppButton />
    <EnvironmentRibbon v-if="isDevelopmentMode" />
    <router-view />
  </a-config-provider>
</template>

<script>
import store from "@/store/index"
import { Modal } from "ant-design-vue"
import { computed, onMounted } from "vue"
import { resetStore } from "@/utils/store-funcitions"
import esES from "ant-design-vue/es/locale/es_ES"
import FloatingWhatsAppButton from "@/components/FloatingWhatsAppButton.vue"
import EnvironmentRibbon from "./components/EnvironmentRibbon.vue"
import { useRouter } from 'vue-router'

export default {
  name: "App",
  components: {
    EnvironmentRibbon,
    FloatingWhatsAppButton
  },
  setup() {

    const router = useRouter()

    const isDevelopmentMode = computed(() => {
      return process.env.NODE_ENV !== "production" ? true : false
    })

    const showSessionTimer = () => {
      let secondsToGo = 30
      const modalInstance = Modal.confirm({
        title: "Cierre de sessión por inactividad",
        centered: true,
        content: `La sesión expirará en ${secondsToGo} segundos.`,
        okText: "Cerrar sesión",
        cancelText: "Mantener la sessión",
        onOk() { // Close session
          clearInterval(interval)
          clearInterval(interval2)
          const properties = ["mousemove", "touchmove", "load", "mousedown", "mouseup", "click", "keypress", "scroll"]
          properties.map((event) => {
            window.removeEventListener(event, null)
          })

          doSignOut()
        },
        onCancel() { // Mantener sesion
          clearInterval(interval)
          clearInterval(interval2)
        }
      })

      const interval = setInterval(() => {
        secondsToGo -= 1
        modalInstance.update({
          content: `La sesión expirará en ${secondsToGo} segundos.`,
        })
      }, 1000)

      const interval2 = setTimeout(() => {
        clearInterval(interval)
        modalInstance.destroy()
        doSignOut()
      }, secondsToGo * 1000)
    }

    const doSignOut = async () => {
      // preserve the current route
      const currentRoute = router.currentRoute.value.path
      console.log('currentRoute', currentRoute)

      // Sign out
      await store.dispatch("signOut")
      store.replaceState(resetStore())

      // Remove token and dateLogin from localStorage
      localStorage.removeItem('token')
      localStorage.removeItem('dateLogin')

      router.push({ name: 'Home', query: { from: currentRoute } })
    }

    onMounted(async () => {
      const timeoutInMiliseconds = 600000
      let timeoutId

      const timer = () => {
        timeoutId = setTimeout(doInactive, timeoutInMiliseconds)
      }

      const token = localStorage.getItem('token')

      if (token) {
        await store.dispatch("validateUser")
      }

      const doInactive = () => {
        if (token && store?.state?.auth?.user?.names) {
          showSessionTimer()
          resetTimer()
        }
      }

      const resetTimer = () => {
        clearTimeout(timeoutId)
        timer()
      }

      timer()

      const properties = ["mousemove", "touchmove", "load", "mousedown", "mouseup", "click", "keypress", "scroll"]
      properties.map((event) => {
        window.addEventListener(event, () => { resetTimer() })
      })
    })


    return {
      isDevelopmentMode
    }
  },
  data() {
    return {
      locale: esES,
    }
  }
}
</script>