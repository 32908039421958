
const authRoutes = [
    {
        path: "/registrar-datos-cuenta",
        name: "Registrar-datos-cuenta",
        props: true,
        component: () => import("@/views/auth/ViewRegister.vue"),
        meta: {
            title: "Registrar cuenta",
        }
    },{
        path: "/recuperar-contrasena",
        name: "Recuperar-contrasena",
        props: true,
        component: () => import("@/views/auth/ViewRecoveryPassword.vue"),
        meta: {
            title: "Recuperar contraseña",
        }
    },{
        path: "/validar-cuenta",
        name: "Validar-cuenta",
        component: () => import("@/views/auth/ViewVerifyCode.vue"),
        meta: {
            title: "Validar cuenta",
        }
    },{
        path: "/validar-solvencia",
        name: "Validar-Solvencia",
        component: () => import("@/views/auth/ViewSolvencyStatus.vue"),
        meta: {
            title: "Validar solvencia",
        }
    },{
        path: "/historial-licencias",
        name: "Historial-Licencias",
        component: () => import("@/views/auth/ViewLicencesHistory.vue"),
        meta: {
            title: "Historial licencias",
        }
    },{
        path: "/validar-cnc",
        name: "Validar-cnc",
        component: () => import("@/views/auth/ViewNonTaxpayerVoucher.vue"),
        meta: {
            title: "Validar cnc",
        }
    },
    {
        path:"/validar-catastro",
        name:"Validar-catastro",
        component:()=> import("@/views/auth/ViewCatastroConstancyVoucher.vue"),
        meta:{
            title:"Validar catastro"
        }
    }
]

export {
    authRoutes
}